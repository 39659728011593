<template>
  <v-container fluid fill-height class="fullscreen-div">
    <v-row justify="center" align="center" class="mt-12 mb-0">
      <v-col cols="12" sm="6" md="4" class="mb-0 col-title">
        <p class="sub-title text-center col-title">Oops!</p>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mt-n16 mt-0 mb-0 col-title">
      <v-col cols="12" sm="6" md="4">
        <p class="sub-title-content text-center">
          No se encontró la página que estabas buscando
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mt-n16 col-title">
      <v-col cols="12" sm="6" md="6">
        <p class="description text-center mt-n16">
          Explora el menú principal para encontrar otras informaciones o usa el
          botón siguiente para continuar navegando.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="hidden-sm-and-down mt-n16">
      <v-col justify="center" align="center" cols="12" sm="6" md="4" class="mt-n16">
         <v-btn rounded color="#00968F" width="308px" height="48px"
          @click="
              () => {
                $router.push('/');
              }
            "
          >
          <p class="btn-text mt-3">Volver a la página de inicio</p>
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="hidden-md-and-up mt-n16">
      <v-col justify="center" align="center" cols="12" sm="6" md="4" class="mt-n16">
        <v-btn rounded color="#00968F" width="258px" height="42px"
          @click="
              () => {
                $router.push('/')
              }
            "
        >
          <p class="btn-text mt-3">Volver a la página de inicio</p>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: ' NotFoundPage',
};
</script>
<style lang="scss" scoped>
@import "./_app.scss";
.fullscreen-div {
  background-color: $secondary-white !important;
}
.col-title {
  margin-bottom: 0 !important;
}
.sub-title-content {
  color: $secondary-fucsia;
  font-size: 28px;
  font-weight: 700;
  font-family: $principal-font !important;
}
.sub-title {
  color: $secondary-fucsia;
  font-size: 72px;
  font-weight: 700;
  font-family: $principal-font !important;
}
.description {
  color: $secondary-black;
  font-size: 24px;
  font-weight: 400;
  font-family: $principal-font !important;
}
.btn-text {
  text-transform: none;
  color: $principal-white;
  font-size: 18px;
  font-weight: 500;
  font-family: $principal-font !important;
}
@media (max-width: 414px) {
  .sub-title-content {
  color: $secondary-fucsia;
  font-size: 20px;
  font-weight: 700;
  font-family: $principal-font !important;
}
.sub-title {
  color: $secondary-fucsia;
  font-size: 52px;
  font-weight: 700;
  font-family: $principal-font !important;
}
.description {
  color: $secondary-black;
  font-size: 18px;
  font-weight: 400;
  font-family: $principal-font !important;
}
.btn-text {
  text-transform: none;
  color: $principal-white;
  font-size: 14px;
  font-weight: 500;
  font-family: $principal-font !important;
}
}
</style>
